<template>
<div>
  <div class="mt-2 mb-2">
    <p class="font-weight-bold">Please choose  
      <span v-if="event.questionsMinimum != event.questionsMaximum">between {{ event.questionsMinimum }} and {{ event.questionsMaximum }}</span>
      <span v-else>{{ event.questionsMinimum }}</span>
      conversation topics for you and the other person to discuss.
    </p>
  </div>
  <div v-for="(q, index) in event.questions" v-bind:key="index">
    <b-form-checkbox :id="'cb'+index" :value="q" v-model="selectedQuestions" class="mt-2 mb-2" :disabled="selectedQuestions.length >= event.questionsMaximum && !selectedQuestions.includes(q)">{{ q }}</b-form-checkbox>
  </div>
  <button class="btn btn-primary" @click="save" :disabled="selectedQuestions.length < event.questionsMinimum || selectedQuestions.length > event.questionsMaximum">Save</button>
</div>
</template>

<script>
import { db } from '@/services/db'
import { getLog } from "@/services/log"
let log = getLog("page-selectq");

export default {
  data() {
    return {
      selectedQuestions: []
    }
  },
  props: {
    user: Object,
    event: Object,
    eventUser:Object
  },
  mounted() {
    if (this.$debug.autoAccept) {
      this.selectedQuestions = [
        "Automattic Response #1",
        "Automattic Response #2",
        "Automattic Response #3",
      ];
      setTimeout(() => {
        this.save();
      }, 2000 * Math.random());
    } else {
      this.selectedQuestions = this.eventUser.questions || [];
    }
  },
  methods: {
    async save() {
      await db.collection(`LiveEvents/${this.event.id}/users`).doc(this.eventUser.id).update({
        questions: this.selectedQuestions,
      });
      log.log("saved questions", this.selectedQuestions);
      this.$parent.eventUserBehavior.on("questionsSelected");
    }
  }
}
</script>